module.exports = {
    tags: [
      {tag: "A", title: "文型"},
      {tag: "B", title: "態"},
      {tag: "C", title: "時制"},
      {tag: "D", title: "助動詞"},
      {tag: "E", title: "不定詞"},
      {tag: "F", title: "動名詞"},
      {tag: "G", title: "分詞"},
      {tag: "H", title: "関係詞"},
      {tag: "I", title: "比較"},
      {tag: "J", title: "仮定法"},
      {tag: "K", title: "接続詞"},
      {tag: "L", title: "その他"},
    ],

    resultMsg: {
      GradeA: '素晴らしい！あとは次回コーチングまでに間違ったところは潰しておきましょう！',
      GradeB: 'いい感じで学習できています。苦手分野を抽出して復習を。次回コーチング時に向けて質問事項をまとめておきましょう。',
      GradeC: 'あともう少し！間違いの復習に加えて、苦手・弱点分野の演習を引き続き頑張っていきましょう。次回コーチング時に向けて質問事項をまとめておきましょう。',
      GradeD: '少し調子が出ませんでしたね。苦手・弱点分野を中心に、さらに気合いを入れて頑張っていきましょう。次回コーチング時に具体的に対策を提示します。',
      GradeE: '少し調子が出ませんでしたね。わからない項目をできるだけ抽出しておいてください。次回コーチング時にしっかりと分析して、次の一手を提示しますね。頑張っていきましょう！'
    },

    rowSelect: {
      limit: 5,
      alert: '選択できる問題数の制限を超えています。'
    },
}
