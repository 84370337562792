import React, { Component } from 'react';

import tableData from './function/TableData';
import History from './History';
import Detail from './Detail';
import Start from './Start';
import Question from './Question';
import Result from './Result';

class Status extends Component {
  constructor(props){
    super(props);
    let history = null;
    if (this.props.data.history) {
      history = this.props.data.history[0];
      this.tableData(history);
      this.tagTableData(history);
    }
    this.state = {
      // history, detail, start, answer, result
      prev: undefined,
      next: 'history',
      data: undefined,
      history: history
    }
    this.changeStatus = this.changeStatus.bind(this);
    this.changeHistoryView = this.changeHistoryView.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
    // window.removeEventListener("mousewheel", this.handleScroll);
  }

  changeStatus(status, e) {
    if (status.actionLog!==undefined) {
      this.props.actionLog(status.actionLog);
    }
    this.setState({
      prev: status.prev,
      next: status.next,
      result: status.result
    });
  }
  handleKeydown(e) {
    if (this.state.next==='history' && this.props.data.history && e.keyCode===39) {
      this.changeStatus({ prev:this.state.next, next:'detail' });
    } else if (this.state.next==='detail' && e.keyCode===37) {
      this.changeStatus({ prev:this.state.next, next:'history' });
    } else if ((this.state.next==='history' || this.state.next==='detail') && e.keyCode===40) {
      this.changeStatus({ prev:this.state.next, next:'start' });
    } else if (this.state.next==='start' && e.keyCode===38) {
      this.changeStatus({ prev:this.state.next, next:this.state.prev });
    } else {
      e.preventDefault();
    }
  }
  handleScroll(e) {
    if ((this.state.next==='history' || this.state.next==='detail')&& e.deltaY>0) {
      this.changeStatus({ prev:this.state.next, next:'start' });
    } else if (this.state.next==='start' && e.deltaY<0) {
      this.changeStatus({ prev:this.state.next, next:this.state.prev });
    }
  }
  changeHistoryView(index, e) {
    if (this.props.data.history) {
      this.tableData(this.props.data.history[index]);
      this.tagTableData(this.props.data.history[index]);
      this.setState({
        history: this.props.data.history[index]
      });
    }
  }

  tableData(data) {
    data['table_data'] = tableData(data.answers);
  }
  tagTableData(data) {
    let tag_table_data = {}
    for (var tag in data.tag_score) {
      tag_table_data[tag] = data.tag_count[tag] ? Math.round((1 - data.tag_score[tag]) * data.tag_count[tag]) : '-';
    }
    data['tag_table_data'] = tag_table_data
  }

  renderArrow(class_, prev, next) {
    return (
      <div className={class_}>
        <button onClick={this.changeStatus.bind(this,{prev:prev, next:next})}>
          <svg className="arrow" viewBox="0 0 30 104">
            <path d="M 0 0 L 30 52 L 0 104">
            </path>
          </svg>
        </button>
      </div>
    )
  }
  renderArrows() {
    switch (this.state.next) {
      case 'history':
        return (
          <div className="arrows_wrapper">
            { this.renderArrow("down", this.state.next, 'start') }
            { this.props.data.history &&
              this.renderArrow("right", this.state.next, 'detail')
            }
          </div>
        );
      case 'detail':
        return (
          <div className="arrows_wrapper">
            { this.renderArrow("down", this.state.next, 'start') }
            { this.renderArrow("left", this.state.next, 'history') }
          </div>
        );
      case 'start':
        return (
          <div className="arrows_wrapper">
            { this.renderArrow("up", this.state.next, this.state.prev) }
          </div>
        );
      default:
        return;
    }
  }
  renderPages() {
    switch (this.state.next) {
      case 'history':
        window.addEventListener("keydown", this.handleKeydown);
        // window.addEventListener("mousewheel", this.handleScroll);
        return (
          <History name={this.props.data.username}
                   history={this.props.data.history}
                   current={this.state.history}
                   onClick={(status)=>this.changeHistoryView(status)} />
        );
      case 'detail':
        return (
          <Detail name={this.props.data.username}
                  history={this.props.data.history}
                  current={this.state.history}
                  onClick={(status)=>this.changeHistoryView(status)} />
        );
      case 'start':
        return (
          <Start data={this.props.data}
                 onClick={(status)=>this.changeStatus(status)}/>
        );
      case 'answer':
        window.removeEventListener("keydown", this.handleKeydown);
        // window.removeEventListener("mousewheel", this.handleScroll);
        return (
          <Question testset={this.props.data.testset}
                    onClick={(status)=>this.changeStatus(status)}
                    submitTest={(result)=>this.props.submitTest(result)}
                    actionLog={(log)=>this.props.actionLog(log)} />
        );
      case 'result':
        return (
          <Result testset={this.props.data.testset}
                  result={this.state.result}
                  onClick={(status)=>this.changeStatus(status)} />
        );
      default:
        return;
    }
  }

  render() {
    return (
      <div className="wrapper">
        {this.renderPages()}
        {this.renderArrows()}
      </div>
    );
  }
}

export default Status;
