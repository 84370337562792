import React, { Component } from 'react';

import post from './function/axios';

import Selection from './Selection';
import Status from './Status';

import createSessionId from './function/CreateSessionId';

class Session extends Component {
  constructor(props){
    super(props);
    this.uid = this.props.uid;
    this.state = {
      sessionID: undefined,
      data: undefined,
      actionLog: { login: { timestamp: Date.now()},
                            uid: this.uid },
      result: undefined,
    }
    this.setTest = this.setTest.bind(this);
    this.submitTest = this.submitTest.bind(this);
  }

  addActionLog(log) {
    let actionLog = {...this.state.actionLog}
    if (log.event) {
      if ('events' in actionLog) {
        actionLog.events.push(log.event);
      } else {
        actionLog.events = [log.event];
      }
    } else {
      Object.keys(log).map((key)=>{
        actionLog[key] = log[key];
        return null;
      })
    }
    this.setState({ actionLog: actionLog });
  }

  setTest() {
    let now = Date.now();
    let session_id = createSessionId(this.uid,
                                     this.props.session.testset.textID,
                                     now);
    this.setState({ data: this.props.session,
                    sessionID: session_id,
                    actionLog: { ...this.state.actionLog,
                                 session_id: session_id,
                                 session_start: { timestamp: now },
                                 questions: this.props.session.testset.questions } });
  }
  submitTest(submit_data) {
    submit_data.displayed_tags = this.state.data.tags;
    submit_data.displayed_msgs = this.state.data.advice;
    try {
      post({  uid: this.uid,
              sessionID: this.state.sessionID,
              testset: this.state.data.testset,
              result: submit_data,
              actionLog: { ...this.state.actionLog,
                          submit: { timestamp: Date.now() }} }, '/session/end')
      .then((r) => {
        console.log(r.data.res);
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      (!this.state.data) ?
        <Selection onClick={this.setTest}
                    test_type={this.props.session.test_type}/>
      :
        <Status data={this.state.data}
                actionLog={(log)=>this.addActionLog(log)}
                submitTest={this.submitTest}/>
    );
  }
}

export default Session;
