import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class QConfidenceButtons extends Component {
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(value, e) {
    this.props.onClick(value);
  }

  render() {
    return (
      <div className="button_group_confidence">
        <Button className={`btn_confidence ${(this.props.confidence===1)?'conf_active':'conf_true'}`}
                disabled={this.props.disable}
                onClick={this.onClick.bind(this,1)}>
          <span>確信あり</span>
          <div className="icon">
            <div className="circle_outer"></div>
            <div className="circle_inner"></div>
          </div>
        </Button>
        <Button className={`btn_confidence ${(this.props.confidence===0)?'conf_active':'conf_false'}`}
                disabled={this.props.disable}
                onClick={this.onClick.bind(this,0)}>
          <span>確信なし</span>
          <div className="icon">
            <div className="circle_outer">？</div>
          </div>
        </Button>
      </div>
    );
  }
}

export default QConfidenceButtons;
