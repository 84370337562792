import React, { Component } from 'react';

import msToTime from './function/MsToTime';

class CountDownTimer extends Component {
  constructor(props){
    super(props);
    this.state = {
      now: null,
      display: null,
      time_warn: null
    }
    this.update = 50;
    this.interval = null;
    this.countDown = this.countDown.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  countDown() {
    let now = Date.now()
    let elapsedTime = now - this.state.now;
    let time = this.state.display - elapsedTime;
    let time_warn = (time < this.props.warn_lessthan) ? true : false;
    this.setState({
      now: now,
      display: time,
      time_warn: time_warn
    });
  }
  clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  componentDidMount() {
    this.clearInterval();
    this.setState({
      now: Date.now(),
      display: this.props.limit - this.props.elapsed,
      time_warn: (this.props.data < this.props.warn_lessthan) ? true : false
    });
    this.interval = setInterval(this.countDown, this.update);
  }
  componentWillUnmount() {
    this.clearInterval();
  }
  componentDidUpdate(prevProps) {
    if (this.props.index !== prevProps.index) {
      this.componentDidMount();
    }
    if (this.props.run !== prevProps.run) {
      this.props.run ? this.componentDidMount() : this.componentWillUnmount();
    }
  }

  render() {
    return (
      <div className={`timer_wrapper ${this.props.add_class}`}>
        <h3>{this.props.add_class==="timer_total"?"全体の残り解答時間":"本問の目標解答時間"}</h3>
        <div className="remain">
          <div className={this.state.time_warn?'time_warn':''}>{msToTime(this.state.display)}</div>
        </div>
        <div className="time_ref">(分：秒：10ミリ秒)</div>
      </div>
    );
  }
}

export default CountDownTimer;
