import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class QListButton extends Component{
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    this.props.onClick(this.props.index);
  }

  render() {
      if (this.props.confidence===1) {
        return(<Button className="btn_qlist conf_true"
                       onClick={this.onClick}
                       disabled={this.props.disable}>
                 <span>{this.props.index+1}</span>
               </Button>);
      } else if (this.props.confidence===0) {
        return(<Button className="btn_qlist conf_false"
                       onClick={this.onClick}
                       disabled={this.props.disable}>
                 <span>{this.props.index+1}</span>
               </Button>);
      } else {
        return(<Button className="btn_qlist conf_undef"
                       onClick={this.onClick}
                       disabled={this.props.disable}>
                 <span>{this.props.index+1}</span>
               </Button>);
      }
  }
}

export default QListButton;
