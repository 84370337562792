import React, { Component } from 'react';
import {
  Radar, RadarChart, PolarGrid, Legend,
  PolarAngleAxis, PolarRadiusAxis, Tooltip
} from 'recharts';

import { tags } from './setting/phrase';

class Chart extends Component {
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
    this.update = 50;
    this.interval_unit = null;
    this.interval_label = null;
  }  

  onClick(e) {
    if (this.props.small) {
      this.props.onClick();
    }
  }

  // チャートの要素が "出題なし" かを監視
  // "出題なし" なら単位を display: None にする
  componentDidMount() {
    this.componentWillUnmount();
    this.adjustLabelPosition();
    if (!this.props.small) {
        this.interval_unit = setInterval(this.addUnit, this.update);
        this.interval_label = setInterval(this.adjustLabelPosition, this.update);
    }
  }
  componentWillUnmount() {
    if (this.interval_unit) {
      clearInterval(this.interval_unit);
    }
    if (this.interval_label) {
      clearInterval(this.interval_label);
    }
  }
  addUnit() {
    let values = document.getElementsByClassName('recharts-tooltip-item-value');
    let units = document.getElementsByClassName('recharts-tooltip-item-unit');
    for (var i = 0; i < values.length; i++){
      if (values[i].innerHTML === "出題なし") {
          units[i].style.display = "None";
      } else {
          units[i].style.display = "inline";
      }
    }
  }

  adjustLabelPosition() {
      let labels = document.getElementsByClassName('recharts-polar-angle-axis-tick-value');
      let tagcount = tags.length;
      let s, e;
      if (tagcount % 2 === 0) {
          s = tagcount / 2 - 1;
          e = s + 3;
      } else {
          s = (tagcount - 1) / 2 - 1;
          e = s + 4; 
      }
      for (var i = s; i < e; i++){
        labels[i].innerHTML = labels[i].innerHTML.replace("0em", "0.7em");
      }
  }

  shape_for_chart() {
    let chartdata = [];
    tags.map((x) => {
      let user = Math.round((1-this.props.user[x.tag]) * 100);
      let avg = this.props.avg ? Math.round((1-this.props.avg[x.tag]) * 100) : null;
      chartdata.push({
        tag: x.title,
        user: !isNaN(user) ? user : '出題なし',
        avg:  !isNaN(avg) ? avg : '出題なし'
      });
      return true;
    })
    return chartdata;
  }

  render() {
    return (
      <div className={'chart ' + (this.props.class) ? this.props.class : ''} >
        { this.props.small && <h3>{this.props.title}</h3> }
        { this.props.small && <span className="datetime">実施日: {this.props.dt}</span> }
        <RadarChart cx="50%" cy="50%" outerRadius={this.props.radius} width={this.props.size} height={this.props.size} data={this.shape_for_chart()} onClick={this.onClick}>
          <PolarGrid />
          <PolarAngleAxis dataKey="tag" />
          <PolarRadiusAxis angle={30} domain={[0, 100]}/>
          { this.props.avg && <Radar name="平均" dataKey="avg" stroke="#ff7f50" strokeWidth={4} strokeDasharray="3 3" fillOpacity={0} unit="%"/> }
          <Radar name="あなた" dataKey="user" stroke="#4169e1" strokeWidth={2.5} fillOpacity={0} unit="%"/>
          { !this.props.small && <Legend /> }
          { !this.props.small && <Tooltip /> }
        </RadarChart>
      </div>
    );
  }
}

export default Chart;
