export default function createSessionId(uid, textID, ts) {
  // dt: GMT, getMonth(), getDate() は localtime を返す
  let dt = new Date(ts);
  let y    = dt.getFullYear();
  let m    = `00${dt.getMonth() + 1}`.slice(-2);
  let d    = `00${dt.getDate()}`.slice(-2);
  let hour = `00${dt.getHours()}`.slice(-2);
  let min  = `00${dt.getMinutes()}`.slice(-2);
  let sec  = `00${dt.getSeconds()}`.slice(-2);
  let ms   =  `000${dt.getMilliseconds()}`.slice(-3);

  return `${uid}_${textID}_${y}-${m}-${d}-${hour}-${min}-${sec}.${ms}`;
}
