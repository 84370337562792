export default function qFormatPdf(str) {
  // カッコ
  str = str.replace(/\(\s*\)/g, '(　　　)');
  // 下線
  let text = [];
  str = str.replace(/(\d)_/g, '$1._');
  let underline = str.match(/_([^_]*)_/g);
  if (underline!==null) {
    let normal = str.replace(/_([^_]*)_/g, '______').split('______');
    let index = 0;
    while (index < str.length) {
      if (str.indexOf(normal[0],index)===index) {
        text.push(normal[0]);
        index += normal[0].length;
        normal.shift();
      } else if (str.indexOf(underline[0],index)===index) {
        text.push({
          text: underline[0].slice(1,-1),
          style: 'underline'
        })
        index += underline[0].length;
        underline.shift();
      } else {
        index = 99999;  // error
      }
    }
    if (index === 99999) {
      text = str;
    }
  } else {
    text.push(str);
  }
  return text;
}
