import React, { Component } from 'react';

import UserStatus from './UserStatus';
import Chart from './Chart';
import ChartSmall from './ChartSmall';

class History extends Component {
  render() {
    return (
      <div className="history">
        <UserStatus name={this.props.name} data={this.props.current} />
        <div className="history_charts_wrapper status_inner">
          <div className="history_charts_inner">
            {
              this.props.history ? (
                <div>
                  <div className="chart_wrapper">
                    <Chart user={this.props.current.tag_score}
                           avg={this.props.current.tag_avg}
                           size={350}
                           radius={120}/>
                  </div>
                  <div className="chart_small_wrapper">
                    <ChartSmall data={this.props.history} onClick={this.props.onClick} />
                    <div className="order">
                      <svg className="arrow older" viewBox="0 0 28.88 32.5">
                        <path d="M 0 0
                                 L 28.88 14.75
                                 L 0 32.5
                                 C 0 32.5 10.4 19.87 10.4 14.75
                                 C 10.4 9.65 0 0 0 0 Z">
                        </path>
                      </svg>
                      <svg className="arrow newer" viewBox="0 0 28.88 32.5">
                        <path d="M 0 0
                                 L 28.88 14.75
                                 L 0 32.5
                                 C 0 32.5 10.4 19.87 10.4 14.75
                                 C 10.4 9.65 0 0 0 0 Z">
                        </path>
                      </svg>
                    </div>
                    <div className="old">
                      <span>Old</span>
                    </div>
                    <div className="new">
                      <span>New</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="no_history">
                  <h3>履歴がありません</h3>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default History;
