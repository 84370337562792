import axios from 'axios';

import Config from '../setting/config';

export default async function post(data, resource){
  let url = Config.config.serverUrl + resource;
  const res = await axios.post( url,
                                data,
                                { withCredentials: true });
  return res;
}