class Config {
  static get config() {

    let ENV = 'prod_demo';
    
    let env;
    let sso;
    let serverUrl = '';
    const url = '192.168.1.177';

    if (ENV==='prod' || ENV==='prod_demo') {
      env         = ENV;
      sso         = true;
    } else {
      env         = 'dev';
      if (ENV==='dev_aws') {
        sso         = true;
      } else if (ENV==='dev_local') {
        sso         = false;
      } else if (ENV==='dev_local_node') {
        sso         = false;
        serverUrl   = `http://${url}:5000`;
      }
    }

    return({
      env:          env,
      sso:          sso,
      serverUrl:    serverUrl,
    });
  }
}

export default Config;
