import React, { Component } from 'react';

import QListButton from './QListButton';

class QList extends Component{
  renderButtons() {
    let buttons = this.props.data.map((item, i) =>
      <QListButton key={i}
                   index={i}
                   confidence={this.props.confidence[i]}
                   onClick={(index)=>this.props.onClick(index)}
                   disable={this.props.disable}/>
    );
    return buttons;
  }

  renderSpace() {
    let fraction = this.props.data.length % 15;
    if (fraction !== 0) {
      let spacer = [...Array(15 - fraction)].map((_, i) => 
        <div key={i} className="spacer"></div>
      );
      return spacer;
    }
  }

  render() {
    return (
      <div className="qlist">
        {this.renderButtons()}
        {this.renderSpace()}
      </div>
    );
  }
}

export default QList;
