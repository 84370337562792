import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class Selection extends Component {
  onClick(e){
    this.props.onClick();
  }

  render() {
    return(
      <div className="wrapper">
        <div className="selection_wrapper">
          <div className="selection_btns">
            <Button className={(this.props.test_type==='recommend') ? "btn_selection rec_active" : "btn_selection rec_inactive"}
                    onClick={this.onClick.bind(this)}
                    disabled={(this.props.test_type==='recommend')?false:true}
                    block />
            <Button className={(this.props.test_type==='practical') ? "btn_selection pra_active" : "btn_selection pra_inactive"}
                    onClick={this.onClick.bind(this)}
                    disabled={(this.props.test_type==='practical')?false:true}
                    block />
          </div>
          {this.props.test_type===null && <div className="selection_notest"><span>現在、解答できるテストはありません</span></div>}
        </div>
      </div>
    );
  }
}

export default Selection;
