import React, { Component } from 'react';

import dtToDateStr from './function/DtToDateStr';
import Chart from './Chart';

class ChartSmall extends Component {
  onClick(_id, e) {
    this.props.onClick(_id);
  }

  render() {
    return (
      <div className="chart_small_inner">
        { this.props.data.map((data,i)=>{return(<Chart key={i}
                                                       user={data.tag_score}
                                                       avg={data.tag_avg}
                                                       size={160}
                                                       radius={40}
                                                       class={'chart_small'}
                                                       small={true}
                                                       title={data.title}
                                                       dt={dtToDateStr(data.dt)}
                                                       onClick={this.onClick.bind(this,i)}/>);}) }
      </div>
    );
  }
}

export default ChartSmall;
