import React, { Component } from 'react';

import msToTimeJa from'./function/MsToTimeJa';
import dtToDateStr from'./function/DtToDateStr';

class UserStatus extends Component {
  renderTable() {
    return(
      <div className="table">
        <div className="cell_height_double">
          <div className="pink"><h3>{this.props.data && this.props.data.title!==undefined && this.props.data.title!==null ? this.props.data.title : '履歴がありません'}</h3></div>
        </div>
        <div className="cell_top">
          <div className="orange"><span>目標時間</span></div>
          <div><span>{this.props.data && this.props.data.target_time!==undefined && this.props.data.target_time!==null ? msToTimeJa(this.props.data.target_time) : '-'}</span></div>
        </div>
        <div className="cell_top">
          <div className="orange"><span>目標点数</span></div>
          <div><span>{this.props.data && this.props.data.target_score!==undefined && this.props.data.target_score!==null ? this.props.data.target_score : '-'}点</span></div></div>
        <div className="cell">
          <div className="blue"><span>実施日</span></div>
          <div><span>{this.props.data && this.props.data.dt!==undefined && this.props.data.dt!==null ? dtToDateStr(this.props.data.dt) : '-'}</span></div>
        </div>
        <div className="cell">
          <div className="blue"><span>制限時間</span></div>
          <div><span>{this.props.data && this.props.data.limit_time!==undefined && this.props.data.limit_time!==null ? msToTimeJa(this.props.data.limit_time) : '-'}</span></div>
        </div>
        <div className="cell">
          <div className="blue"><span>平均解答時間</span></div>
          <div><span>{this.props.data && this.props.data.avg_time!==undefined && this.props.data.avg_time!==null ? msToTimeJa(this.props.data.avg_time) : '-'}</span></div>
        </div>
        <div className="cell">
          <div className="blue"><span>平均点</span></div>
          <div><span>{this.props.data && this.props.data.avg!==undefined && this.props.data.avg!==null ? Math.floor((1-this.props.data.avg)*100) : '-'}点</span></div>
        </div>
        <div className="cell">
          <div className="green"><span>順位</span></div>
          <div><span>{this.props.data && this.props.data.rank!==undefined && this.props.data.rank!==null ? this.props.data.rank : '-'}位</span>
               <span className="small">({this.props.data && this.props.data.count!==undefined && this.props.data.count!==null ? this.props.data.count : '-'}人中)</span></div>
        </div>
        <div className="cell">
          <div className="green"><span>偏差値</span></div>
          <div><span>{this.props.data && this.props.data.dev_value!==undefined && this.props.data.dev_value!==null ? this.props.data.dev_value : '-'}</span></div>
        </div>
        <div className="cell">
          <div className="green"><span>解答時間</span></div>
          <div><span>{this.props.data && this.props.data.total_time!==undefined && this.props.data.total_time!==null ? msToTimeJa(this.props.data.total_time) : '-'}</span></div>
        </div>
        <div className="cell">
          <div className="green"><span>点数</span></div>
          <div><span>{this.props.data && this.props.data.score!==undefined && this.props.data.score!==null ? Math.floor((1-this.props.data.score)*100) : '-'}点</span>
               <span className="small">({this.props.data && this.props.data.score_count!==undefined && this.props.data.score_count!==null ? this.props.data.score_count : '-'})</span></div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="user_status status_inner">
        <div className="title"><h2>STATUS</h2></div>
        <div className="name"><h3>{this.props.name}</h3></div>
        { this.renderTable() }
      </div>
    );
  }
}

export default UserStatus;
