import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import msToTime from './function/MsToTime';

class Start extends Component {
  onClick(e){
    this.props.onClick({ next:'answer', actionLog: { test_start: { timestamp:Date.now() }}});
  }

  fillArr(arr, count) {
    arr = arr ? arr : new Array(count);
    if (arr.length < count) {
      for (let i = 0; i < count - arr.length + 1; i++) { arr.push('-') }
    }
    return arr;
  }

  renderTags() {
    let arr = this.fillArr(this.props.data.tags, 2);
    return(
      arr.map((tag,i)=>{let num=i+1; return(<div key={i}><span className="num">No.{num}</span><span className="tag">{tag}</span></div>);})
    );
  }

  renderAdvices() {
    let arr = this.fillArr(this.props.data.advice, 2);
    let classes = arr.map((x)=>{return((x==='-') ? 'tag' : 'msg')});
    return(
      arr.map((msg,i)=>{let num=i+1; return(<div key={i}><span className="num">No.{num}</span><span className={classes[i]}>{msg}</span></div>);})
    );
    
  }

  render() {
    return (
      <div className="start status_inner">
        <div className="book_wrapper">
          <svg className="line_outer" viewBox="540 646.24 1295.307 599.134">
            <path d="M 540 677.6024780273438 C 540 677.6024780273438 544.915771484375 1097.901123046875 540 1245.37451171875 C 832.4885864257812 1213.421752929688 955.3829345703125 1223.253540039062 1183.966552734375 1245.37451171875 C 1392.886840820312 1210.963989257812 1835.306518554688 1245.37451171875 1835.306518554688 1245.37451171875 C 1832.848510742188 1058.574829101562 1835.306518554688 876.69140625 1835.306518554688 677.6024780273438 C 1587.059936523438 643.192138671875 1427.29736328125 638.2764282226562 1188.88232421875 682.5183715820312 C 911.1409912109375 603.8659057617188 540 677.6024780273438 540 677.6024780273438 Z">
            </path>
          </svg>
          <svg className="line_center">
            <path d="M 0 0 L 0 437"></path>
          </svg>
        </div>

        <div className="book_wrapper">
          <div className="weak_tags">
            <div className="title">
              <svg className="hexagon" viewBox="6544.733 -927.398 86.311 99.313">
                <path d="M 6587.88818359375 -927.3982543945312 L 6631.04345703125 -896.8827514648438 L 6631.04345703125 -858.6011352539062 L 6587.88818359375 -828.0856323242188 L 6544.73291015625 -858.6011352539062 L 6544.73291015625 -896.8827514648438 L 6587.88818359375 -927.3982543945312">
                </path>
              </svg>
              <h3>苦手分野</h3>
            </div>
            <div className="start_msg_wrapper">{this.renderTags()}</div>
          </div>

          <div className="advices">
            <div className="title">
              <svg className="hexagon" viewBox="6544.732 -927.398 86.311 99.313">
            		<path d="M 6587.8876953125 -927.398193359375 L 6631.04296875 -896.8826904296875 L 6631.04296875 -858.60107421875 L 6587.8876953125 -828.0855712890625 L 6544.732421875 -858.60107421875 L 6544.732421875 -896.8826904296875 L 6587.8876953125 -927.398193359375">
            		</path>
            	</svg>
              <h3>目標・アドバイス</h3>
            </div>
            <div className="start_msg_wrapper">{this.renderAdvices()}</div>
          </div>
      	</div>

        <div className="test_info count">
          <h3>問題数</h3>
          { this.props.data.testset.questions && <span>{this.props.data.testset.questions.length} 問</span> }
        </div>
        <div className="test_info time">
          <h3>目標時間</h3>
          { this.props.data.testset.target_time && <span>{msToTime(this.props.data.testset.target_time)}</span> }
          <span className="time_ref">(分：秒：10ミリ秒)</span>
        </div>
        <Button className="btn_start" onClick={this.onClick.bind(this)}>スタート</Button>
      </div>
    );
  }
}

export default Start;
