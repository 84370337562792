import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import { rowSelect } from './setting/phrase';

class Table extends Component {
  constructor(props){
    super(props);
    const columns = [{
      dataField: 'num',
      text: '問題番号',
      sort: true
    }, {
      dataField: 'judge',
      text: '正誤',
      sort: true
    }, {
      dataField: 'confidence',
      text: '確信',
      sort: true
    }, {
      dataField: 'time',
      text: '時間',
      sort: true
    }];
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.onClick(row.num-1);
      }
    }
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.state = {
      columns: columns,
      selected: this.props.default,
      num: this.props.default ? this.props.default.length : 0 
    }
  }

  handleOnSelect(row, isSelect) {
    let count = this.state.num;
    if (isSelect) {
      count++;
    } else {
      count--;
    }
    if (count>this.props.limit) {
      alert(rowSelect.alert);
      return false;
    } else {
      if (isSelect) {
        this.setState({num: count, selected: [...this.state.selected, row.num]});
      } else {
        this.setState({num: count, selected: this.state.selected.filter(x => x !== row.num)});
      }
      return true;
    }
  }

  render() {
    const selectRow = {
      mode: 'checkbox',
      selected: this.state.selected,
      hideSelectAll: true,
      style: { backgroundColor: '#a8edea' },
      onSelect: this.handleOnSelect
    };
    return(
      this.props.checkbox ? (
        <BootstrapTable
          ref='table'
          keyField='num'
          data={ this.props.data }
          columns={ this.state.columns }
          selectRow={ selectRow }
          rowEvents={ this.rowEvents }
          hover
          condensed
        />
      ) : (
        <BootstrapTable
          keyField='num'
          data={ this.props.data }
          columns={ this.state.columns }
          rowEvents={ this.rowEvents }
          hover
          condensed
        />
      )
    );
  }
}

export default Table;
