export default function msToTime(ms) {
  let sign = '';
  if (ms < 0) {
    ms *= -1;
    sign = '-';
  }
  let tenmilisec = Math.floor((ms % 1000) / 10);
  let time = Math.floor(ms/1000);
  let sec = time % 60;
  let min = Math.floor(time/60);
  return `${sign}${min}:${('0'+sec).slice(-2)}:${('0'+tenmilisec).slice(-2)}`
}
