import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import qFormatPdf from './QFormatPdf';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Pdf {
  constructor(data){
    this.data = data;
  }

  pdfText() {
    let texts = [
      {
        text: this.data.title,
        style: 'title'
      },
      {
        text: this.data.date,
        style: 'date',
      }
    ];
    this.data.questions.map((q)=>{this.questionToText(texts, q); return true;});
    return texts;
  }

  questionToText(texts, q) {
    texts.push({
      text:  `第${q.num}問`,
      style: 'qnum'
    });
    q.instruction && q.instruction.map((q)=>{
      texts.push({
        text: q
      })
      return true;
    });
    q.question && q.question.map((q)=>{
      texts.push({
        text: qFormatPdf(q)
      })
      return true;
    });
    let choices = q.choices && qFormatPdf(q.choices.map((c,i)=>{return(`${i+1}. ${c}`);}).join(' / '));
    q.choices && texts.push({
        text: choices,
        style: 'choices'
    });
    texts.push({
      text: `正誤: ${q.judge}`
    });
    let ans = (q.ans && q.choices.indexOf(q.ans)!==-1) ? qFormatPdf(`解答: ${q.choices.indexOf(q.ans)+1}. ${q.ans}`) : '解答:';
    q.choices &&  texts.push({
      text: ans
    });
    let ans_rev = q.ans_rev ? ` → ${q.ans_rev}` : '';
    let correct_ans = q.choices && q.choices.indexOf(q.correct_ans)!==-1 ? qFormatPdf(`正解: ${q.choices.indexOf(q.correct_ans)+1}. ${q.correct_ans}${ans_rev}`) : '正解:';
    q.choices && texts.push({
      text: correct_ans
    });
  }

  download() {
    if (this.data) {
      pdfMake.fonts = {
        GenYoMin: {
          normal: 'GenYoMinJP-Regular.ttf',
          // normal: 'NotoSansCJKjp-DemiLight.otf',
        }
      };
      const defaultStyle = 'GenYoMin';
      // const defaultStyle = 'NotoSansCJKjp';
      const docDefinition = {
        content: this.pdfText(),
        defaultStyle: { font: defaultStyle, fontSize: 12 },
        styles: {
                   title: { fontSize: 20 },
                   date:  { fontSize: 10, alignment: 'right' },
                   choices: { margin: [0, 5, 0, 5] },
                   qnum:  { margin: [0, 15, 0, 8] },      // left, top, right, bottom
                   underline: { decoration: 'underline' }
                },
      };
      pdfMake.createPdf(docDefinition).open();
    }
  };
}

export default Pdf;
