import msToTime from './MsToTime';

export default function tableData(data) {
  return data.map((q) => {
    return({
      num: q.num,
      judge: (q.judge===0) ? '〇' : '×',
      confidence: (q.confidence===1) ? 'あり' : 'なし',
      time: msToTime(q.time)
    });
  });
}
