import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import tableData from './function/TableData';
import Pdf from './function/Pdf';
import Chart from './Chart';
import Table from './Table';
import TagTable from './TagTable';
import Review from './Review';

class Result extends Component {
  constructor(props){
    super(props);
    // tag table count, score
    this.tag_table_count = {}
    this.tag_table_score = {}
    for (var tag in this.props.result.tag_count) {
      this.tag_table_count[tag] = this.props.result.tag_count[tag]['total'];
      this.tag_table_score[tag] = this.props.result.tag_count[tag]['total'] - this.props.result.tag_count[tag]['score'];
    }
    this.table_data = tableData(this.props.result.questions);

    // checkbox default select
    let results = this.props.result.questions;
    this.default_select = [];
    results.map((q,i)=>{
      if (q.judge===1) { this.default_select.push(i+1); }
      return true;
    });
    this.select_limit = Math.max(Math.round(results.length*0.4),this.default_select.length+5);
    this.state = {
      index: null,
      pdf: false,
      window_close:false
    }
    this.setReview = this.setReview.bind(this);
  }

  end(e){
    window.close();
    this.setState({window_close: true});
  }
  reload(e){
    window.location.reload();
  }

  setReview(index, e){
    this.setState({
      index: index
    })
  }

  makePdf(e) {
    let qlist = this.refs.table.refs.table.selectionContext.selected;
    if (qlist.length > 0) {
      let q = qlist.map((q,i)=>{
        let question = this.props.testset.questions[q-1];
        let result = this.props.result.questions[q-1];
        return({
          num: result.num,
          instruction: question.instruction,
          question: question.question,
          choices: question.choices.map(x=>x.value),
          correct_ans: question.answer,
          ans_rev: question.answer_revise,
          ans: result.ans,
          judge: (result.judge===0) ? '〇' : '×'
        });
      });
      q.sort((x,y)=>{return(x.num<y.num?-1:1);});
      let today = new Date();
      let data = {
        title: this.props.testset.title,
        date: `${today.getFullYear()}/${today.getMonth()+1}/${today.getDate()}`,
        questions: q
      };
      let pdf = new Pdf(data);
      pdf.download();
    }
  }

  render() {
    let question;
    if (this.state.index!==null) {
      let q = this.props.testset.questions[this.state.index];
      question = {
        QID: q.QID,
        instruction: q.instruction,
        question: q.question,
        choices: q.choices.map(x=>x.value),
        answer: q.answer,
        answer_revise: q.answer_revise,
        tags: q.tags,
      }
    }
    return (
      this.state.window_close ? (
        <div className="logout_msg">ブラウザを閉じて終了してください</div>
      ) : (
        <div className="result">
          <div className="result_wrapper">
            <div className="title_wrapper">
              <div className="title_logo"></div>
              <div className="title"><h2>{this.props.testset.title}</h2></div>
            </div>
            <div className="chart_wrapper">
              <Chart user={this.props.result.tag_score}
                    avg={this.props.testset.tag_avg}
                    size={350}
                    radius={120} />
            </div>
          </div>

          <div className="result_wrapper">
            <h3>RESULT</h3>
            <div className="score"><span>SCORE: {Math.round((1-this.props.result.score)*100)} [{this.props.result.score_count}]</span></div>
            <div className="result_inner">
              <TagTable count={this.tag_table_count} score={this.tag_table_score} />
              <div className="testset">
                <span className="checkbox_head">復習希望</span>
                <Table data={this.table_data}
                      checkbox={true}
                      default={this.default_select}
                      limit={this.select_limit}
                      onClick={this.setReview}
                      ref="table"/>
                <div className="review_wrapper">
                  {this.state.index===null ? <Review/> : <Review num={this.state.index+1} 
                                                                question={question} 
                                                                answer={this.props.result.questions[this.state.index].ans} />}
                </div>
              </div>
              <div className="output">
                <Button className="btn_pdf" onClick={this.makePdf.bind(this)}>出力</Button>
                <span>チェックした問題を出力します。</span>
              </div>
            </div>
            <div className="advice_wrapper">
              <h4>アドバイス</h4>
              <div className="advice"><span>{this.props.result.advice}</span></div>
            </div>
          </div>
          <div className="btn_group">
            <Button className="btn_ btn_back" onClick={this.reload.bind(this)}>問題選択画面へ</Button>
            <Button className="btn_ btn_end" onClick={this.end.bind(this)}><span>アプリ終了</span></Button>
          </div>
        </div>
      )
    );
  }
}

export default Result;
