import React, { Component } from 'react';

import qFormat from './function/QFormat';
import { tags } from './setting/phrase';

class Review extends Component {
  render() {
    const tags_ = tags.map((x)=>x.tag);
    return (
      (this.props.question===undefined&&this.props.answer===undefined) ? (
        <div className="review_inner">
          <span>表をクリックすると、出題された問題を確認できます。</span>
        </div>
      ) : (
        <div className="review_inner">
          <div>
            <div>第{this.props.num}問</div>
            <div>{ this.props.question && this.props.question.instruction && this.props.question.instruction.map((q,i)=>{return(<span key={i} className="instruction">{this.props.question.instruction}</span>);}) }</div>
            <div>{ this.props.question && this.props.question.question && this.props.question.question.map((q,i)=>{return(<div key={i} className="question q_format" dangerouslySetInnerHTML={qFormat(q)} />);}) }</div>
          </div>
          <div>{ this.props.question && this.props.question.choices && this.props.question.choices.map((c,i)=>{return(<div key={i} className="choices q_format" dangerouslySetInnerHTML={qFormat(c)} />);}) }</div>
          <div><span>あなたの解答: </span>{ this.props.answer && <div className="answer q_format" dangerouslySetInnerHTML={qFormat(this.props.answer)} /> }</div>
          <div><span>正解: </span>{ this.props.question && this.props.question.answer && <div className="answer q_format" dangerouslySetInnerHTML={qFormat(this.props.question.answer)} /> }
                                                          { this.props.question && this.props.question.answer_revise && <span> → {this.props.question.answer_revise}</span>}</div>
          <div><span>分野: </span><span>{this.props.question && this.props.question.tags && this.props.question.tags.filter((x)=>{if(tags_.indexOf(x)!==-1){return x;}; return null;}).join(', ')}</span></div>
        </div>
      )
    );
  }
}

export default Review;