import React, { Component } from 'react';

import { tags } from './setting/phrase';

class TagTable extends Component {
  render() {
    return (
      <div className="tags">
        <div><span>分野</span>{ tags.map((x,i)=>{return(<span key={i}>{x.tag}</span>);}) }</div>
        <div><span>分野名</span>{ tags.map((x,i)=>{return(<span key={i}>{x.title}</span>);}) }</div>
        <div><span>出題数</span>{ tags.map((x,i)=>{return(<span key={i}>{ (x.tag in this.props.count) ? this.props.count[x.tag] : '-' }</span>);}) }</div>
        <div><span>正解数</span>{ tags.map((x,i)=>{return(<span key={i}>{ (x.tag in this.props.score) ? this.props.score[x.tag] : '-' }</span>);}) }</div>
      </div>
    );
  }
}

export default TagTable;
