import React, { Component } from 'react';

import DatePicker, { registerLocale } from 'react-datepicker';
import ja from "date-fns/locale/ja";

registerLocale("ja", ja);

class SummaryCalendar extends Component {
  constructor(props){
    super(props);
    let dates = this.props.data.map((history) => { let dt = new Date(history.dt); return(new Date(dt.getFullYear(),dt.getMonth(),dt.getDate())); });
    let times = dates.map((dt) => { return(dt.getTime()); });
    this.state = {
      date: null,
      dates: dates,
      times: times,
      startDate: dates[dates.length-1],
      endDate: dates[0]
    }
    this.onClick = this.onClick.bind(this);
  }  

  onClick(date) {
    if (this.state.times.includes(date.getTime())){
      this.props.onClick(this.state.times.indexOf(date.getTime()));
    }
  }

  render() {
    return (
      <DatePicker
        inline
        locale='ja'
        minDate={this.state.startDate}
        maxDate={this.state.endDate}
        highlightDates={this.state.dates}
        onChange={this.onClick}
      />
    );
  }
}

export default SummaryCalendar;

// // debug
// let dt = new Date('December 17, 1995 03:24:00');
// minDate={new Date(dt.getFullYear(),dt.getMonth(),dt.getDate())}
