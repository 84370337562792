import React, { Component } from 'react';

import UserStatus from './UserStatus';
import Chart from './Chart';
import SummaryCalendar from './SummaryCalendar';
import Table from './Table';
import TagTable from './TagTable';
import Review from './Review';

class Detail extends Component {
  constructor(props){
    super(props);
    this.state = {
      index: null
    }
    this.onClick = this.onClick.bind(this);
  }

  onClick(index, e) {
    this.setState({
      index: index
    })
  }

  render() {
    return(
      <div className="detail">
        <UserStatus name={this.props.name} data={this.props.current} />
        <div className="detail_wrapper status_inner">
          <div className="detail_inner">
            <SummaryCalendar data={this.props.history} onClick={this.props.onClick}/>
            <div className="chart_wrapper">
              <Chart user={this.props.current.tag_score}
                     avg={this.props.current.tag_avg}
                     size={300}
                     radius={85}/>
            </div>
            <div className="testset">
              <Table data={this.props.current.table_data} onClick={this.onClick}/>
              <div className="review_wrapper">
                <h3>{this.props.current.title}</h3>
                {this.state.index===null ? <Review/> : <Review num={this.state.index+1}
                                                               question={this.props.current.answers[this.state.index].question}
                                                               answer={this.props.current.answers[this.state.index].ans} />}
              </div>
            </div>
            <TagTable count={this.props.current.tag_count}
                      score={this.props.current.tag_table_data} />
          </div>
        </div>
      </div>
    );
  }
}

export default Detail;
